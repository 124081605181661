(function() {
	"use strict";

	let cookieBar = document.querySelector("#cookie-bar");
	let cookieBtn = document.querySelector("#js-cookie-accept");
	let acceptCookie = localStorage.getItem("cpa-cookies-accepted");

	if (!acceptCookie) {
		cookieBar.classList.add("cookie_bar_show");

		cookieBtn.addEventListener("click", () => {
			cookieBar.classList.remove("cookie_bar_show");
			localStorage.setItem("cpa-cookies-accepted", true);
		});
	}
})();
;$(function() {
  $("#dob")
    .datepicker()
    .datepicker("setDate", new Date());
});

$(function() {
  var form = $(".f01-registration input").parsley("validate");

  $(".f01-registration-submit").click(function(event) {
    event.preventDefault();

    var isValid = true;
    $(".f01-registration select").each(function() {
      if (
        $(this)
          .parsley()
          .validate() !== true
      )
        isValid = false;
    });
    $(".f01-registration input").each(function() {
      if (
        $(this)
          .parsley()
          .validate() !== true
      )
        isValid = false;
    });
  });
});
;(function() {
	"use strict";

	// ************** SELECTORS, VARIABLES ************** //

	let headerWrap = document.querySelector(".header__wrapper");
	let mobileNav = document.querySelector(".bottom-row");
	let dtopNav = document.querySelectorAll(".main-nav")[0];
	let burger = document.querySelector(".js-burger-icon");
	let l1Triggers = document.querySelectorAll(".l1-trigger");
	let l2Triggers = document.querySelectorAll(".l2-trigger");
	let ddTrigger = document.querySelector(".util-nav-dd-trigger");
	let ddContent = document.querySelector(".util-nav-dd-content");
	let body = document.getElementsByTagName("body")[0];
	let indexCache = "";
	let resizeTimeout;

	// ************** METHODS ************** //

	const is_mobile_width = () => {
		let viewWidth = document.documentElement.clientWidth;

		return viewWidth < 1024 ? true : false;
	};

	/**
	 *
	 * @param {*} e
	 * Opens target submenu on mobile.
	 * If target is first level item, closes previous submenu before opening new one
	 */
	const toggle_menu_mobile = e => {
		let targetNav = e.target.nextElementSibling;
		let selected = e.target.parentElement;
		let isL2trigger = e.target.classList.contains("l2-trigger") ? true : false;

		if (indexCache !== "" && indexCache !== e.target.id && !isL2trigger) {
			let prev = document.getElementById(indexCache);
			let prevSib = prev.nextElementSibling;
			let prevParent = prev.parentElement;

			prevParent.classList.remove("selected");
			prevSib.classList.remove("opened");
		}

		if (e.target.id !== "" && !isL2trigger) {
			indexCache = e.target.id;

			/* scroll to taget submenu top if outside viewport after previous submenu closes */
			setTimeout(function() {
				if (is_mobile_width() && !checkElInViewport(selected)) {
					scrollIt(selected, 320, "easeOutQuad");
				}
			}, 350);
		}

		selected.classList.toggle("selected");
		targetNav.classList.toggle("opened");
	};

	/**
	 *
	 * @param {*} e
	 * Opens target's submenu in meganav and adds 'selected' class to target
	 */
	const toggle_menu_desktop = e => {
		let targetNav = e.target.children[1];
		let selected = e.target;

		selected.classList.toggle("selected");
		targetNav.classList.toggle("opened");
	};

	/**
	 *
	 * Open/close mobile nav on tap/click burger menu
	 */
	const toggle_nav = () => {
		burger.classList.toggle("js-burger-icon--open");
		mobileNav.classList.toggle("nav-opened");
		body.classList.toggle("nav-open");
	};

	/**
	 *
	 * @param {*} persisted (from event)
	 * Closes desktop menu if navigating back using 'back' button.
	 * Issue occurs in MS Edge
	 */
	const close_cached_menu = persisted => {
		let fromBackBtn = persisted || (typeof window.performance !== "undefined" && window.performance.navigation.type === 2);

		if (fromBackBtn) {
			let desktopHeader = document.querySelectorAll(".header-desktop")[0];
			let anySelected = desktopHeader.querySelectorAll(".selected");

			if (anySelected.length > 0) {
				anySelected[0].children[1].classList.remove("opened");
				anySelected[0].classList.remove("selected");
			}

			if (mobileNav.classList.contains("nav-opened")) {
				toggle_nav();
			}

			if (body.classList.contains("nav-open")) {
				body.classList.remove("nav-open");
			}
		}
	};

	/**
	 *
	 * Resize event only fires twice per second if resizing
	 */
	const resize_throttler = () => {
		if (!resizeTimeout) {
			resizeTimeout = setTimeout(function() {
				resizeTimeout = null;
				resize_events_handler();
			}, 500);
		}
	};

	/**
	 *
	 * Toggle event listeners for desktop nav and mobile nav functionality if resized
	 */
	const resize_events_handler = () => {
		if (is_mobile_width()) {
			for (let i = 0; i < l1Triggers.length; i++) {
				l1Triggers[i].parentNode.removeEventListener("mouseenter", toggle_menu_desktop);
				l1Triggers[i].parentNode.removeEventListener("mouseleave", toggle_menu_desktop);
				l1Triggers[i].parentNode.removeEventListener("touchstart", toggle_menu_desktop);
				l1Triggers[i].addEventListener("click", toggle_menu_mobile);
			}
			for (let j = 0; j < l2Triggers.length; j++) {
				l2Triggers[j].addEventListener("click", toggle_menu_mobile);
			}
		} else {
			for (let i = 0; i < l1Triggers.length; i++) {
				l1Triggers[i].removeEventListener("click", toggle_menu_mobile);
				l1Triggers[i].parentNode.addEventListener("mouseenter", toggle_menu_desktop);
				l1Triggers[i].parentNode.addEventListener("mouseleave", toggle_menu_desktop);
				l1Triggers[i].parentNode.addEventListener("touchstart", toggle_menu_desktop);
			}
			for (let j = 0; j < l2Triggers.length; j++) {
				l2Triggers[j].removeEventListener("click", toggle_menu_mobile);
			}
		}
	};

	// ************** EVENT LISTENERS ************** //

	/**
	 *
	 * Desktop nav
	 * Attach event listeners if in desktop width on page-load
	 */
	if (!is_mobile_width()) {
		for (let i = 0; i < l1Triggers.length; i++) {
			l1Triggers[i].parentNode.addEventListener("mouseenter", toggle_menu_desktop);
			l1Triggers[i].parentNode.addEventListener("mouseleave", toggle_menu_desktop);
			l1Triggers[i].parentNode.addEventListener("touchstart", toggle_menu_desktop);
		}

		dtopNav.addEventListener("mouseenter", () => {
			body.classList.toggle("nav-open");
		});

		dtopNav.addEventListener("mouseleave", () => {
			body.classList.toggle("nav-open");
		});
	}

	/**
	 *
	 * Mobile nav
	 * Attach event listeners if in mobile width on page-load
	 */
	if (is_mobile_width()) {
		for (let i = 0; i < l1Triggers.length; i++) {
			l1Triggers[i].addEventListener("click", toggle_menu_mobile);
		}
		for (let j = 0; j < l2Triggers.length; j++) {
			l2Triggers[j].addEventListener("click", toggle_menu_mobile);
		}
	}

	burger.addEventListener("click", toggle_nav);

	/**
	 *
	 * Dropdown in header utiliies (desktop)
	 */
	ddTrigger.addEventListener(
		"click",
		() => {
			ddTrigger.classList.toggle("opened");
			ddContent.classList.toggle("opened");
		},
		false
	);

	/**
	 *
	 * Dropdown in header utiliies (desktop)
	 * Open on 'enter' keypress
	 */
	ddTrigger.addEventListener("keydown", e => {
		if (e.keyCode === 13) {
			ddTrigger.classList.toggle("opened");
			ddContent.classList.toggle("opened");
		}
	});

	/**
	 *
	 * Allow element outlines when tab key pressed
	 */
	window.addEventListener("keydown", e => {
		if (!headerWrap.classList.contains("tabbed") && e.keyCode === 9) {
			headerWrap.classList.add("tabbed");
		}
	});

	/**
	 *
	 * Calls function to close menu if using browser back button
	 */
	window.addEventListener("pageshow", e => {
		close_cached_menu(e.persisted);
	});

	/**
	 *
	 * Handle navigation on resize
	 * 'resize_throttler" calls 'resize_events_handler'
	 */
	window.addEventListener("resize", resize_throttler, false);
})();
;const scrollIt = function(destination, duration = 200, easing = "linear", callback) {
	const easings = {
		linear(t) {
			return t;
		},
		easeInQuad(t) {
			return t * t;
		},
		easeOutQuad(t) {
			return t * (2 - t);
		},
		easeInOutQuad(t) {
			return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
		},
		easeInCubic(t) {
			return t * t * t;
		},
		easeOutCubic(t) {
			return --t * t * t + 1;
		},
		easeInOutCubic(t) {
			return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
		},
		easeInQuart(t) {
			return t * t * t * t;
		},
		easeOutQuart(t) {
			return 1 - --t * t * t * t;
		},
		easeInOutQuart(t) {
			return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
		},
		easeInQuint(t) {
			return t * t * t * t * t;
		},
		easeOutQuint(t) {
			return 1 + --t * t * t * t * t;
		},
		easeInOutQuint(t) {
			return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
		}
	};

	// Store initial position of a window and time
	// If performance is not available in your browser
	// It will fallback to new Date().getTime() - thanks IE < 10
	const start = window.pageYOffset;
	const startTime = "now" in window.performance ? performance.now() : new Date().getTime();
	// const startTime = typeof(window.performance['now']) == 'function' ? performance.now() : new Date().getTime();

	// Take height of window and document to sesolve max scrollable value
	// Prevent requestAnimationFrame() from scrolling below maximum scollable value
	// Resolve destination type (node or number)
	const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
	const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName("body")[0].clientHeight;
	const destinationOffset = typeof destination === "number" ? destination : destination.offsetTop;
	const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);

	// If requestAnimationFrame is not supported
	// Move window to destination position and trigger callback function
	if ("requestAnimationFrame" in window === false) {
		window.scroll(0, destinationOffsetToScroll);
		if (callback) {
			callback();
		}
		return;
	}

	// function resolves position of a window and moves to exact amount of pixels
	// Resolved by calculating delta and timing function choosen by user
	function scroll() {
		const now = "now" in window.performance ? performance.now() : new Date().getTime();
		const time = Math.min(1, (now - startTime) / duration);
		const timeFunction = easings[easing](time);
		window.scroll(0, Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start));

		// Stop requesting animation when window reached its destination
		// And run a callback function
		if (window.pageYOffset === destinationOffsetToScroll) {
			if (callback) {
				callback();
			}
			return;
		}

		// If window still needs to scroll to reach destination
		// Request another scroll invokation
		requestAnimationFrame(scroll);
	}

	// Invoke scroll and sequential requestAnimationFrame
	scroll();
};

/**
 *
 * @param {*} options
 * @param {*} callback
 * reusable function accepts two params - object to loop through and a callback
 * loops through object and executes callback
 */
const doLoop = (options, callback) => {
	for (let i = 0; i < options.length; i++) {
		if (callback(i, options[i]) === false) break;
	}
};

/**
 *
 * @param {*} el
 * Checks if an element is visible in the viewport, returns true or false
 */
const checkElInViewport = el => {
	const scroll = window.scrollY || window.pageYOffset;
	const boundsTop = el.getBoundingClientRect().top + scroll;

	const viewport = {
		top: scroll,
		bottom: scroll + window.innerHeight
	};

	const bounds = {
		top: boundsTop,
		bottom: boundsTop + el.clientHeight
	};

	return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) || (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
};
;var news = document.querySelector("#js-m07_three_col_dynamic--news");
var jobs = document.querySelector("#js-m07_three_col_dynamic--jobs");
var noTitle = document.querySelector("#js-m07_three_col_dynamic--noTitle");

function slider(category) {
  var newsSlider = tns({
    container: category,
    items: 1,
    slideBy: "page",
    autoplay: false,
    nav: false,
    gutter: 10,
    controls: false,
    mouseDrag: true,
    responsive: {
      768: {
        items: 3,
        gutter: 30
      }
    }
  });
}

if (news) {
  slider(news);
}

if (jobs) {
  slider(jobs);
}

if (noTitle) {
  slider(noTitle);
}
;{
  const filters = document.querySelectorAll(".m13_job_filters--filters li");

  for (let i = 0; i < filters.length; i++) {
    filters[i].addEventListener("click", function(e) {
      filters[i].classList.add("active");
      for (var y = 0; y < filters.length; y++) {
        if (
          filters[i].classList.contains("active") &&
          filters[y] !== filters[i]
        ) {
          filters[y].removeAttribute("class");
        }
      }
    });
  }
}
;(function(){

    let college_select = document.querySelector('#m14_college');
    let course_select= document.querySelector('#m14_course');

    if ( college_select !== null ) {
        college_select.onchange = function() {
            if ( college_select.value === "" ) {
                course_select.disabled = true;
                course_select.value = "";
            } else {
                course_select.disabled = false;
            }
        };  
    }
})();;(function() {
	"use strict";

	// ************** SELECTORS, VARIABLES ************** //

	let m17_triggers = document.querySelectorAll(".accordion-trigger");
	let m17_content_items = document.querySelectorAll(".accordion-item-content");

	// ************** METHODS ************** //0

	let toggle_nav = function(e) {
		let trigger = e.target;
		let target_content = e.target.nextElementSibling;

		if (trigger.classList.contains("selected")) {
			target_content.classList.remove("opened");
			trigger.classList.remove("selected");
		} else {
			for (let i = 0; i < m17_content_items.length; i++) {
				if (m17_content_items[i].classList.contains("opened")) {
					m17_content_items[i].classList.remove("opened");
					m17_triggers[i].classList.remove("selected");
				}
			}

			target_content.classList.toggle("opened");
			trigger.classList.toggle("selected");
		}
	};

	// ************** EVENT LISTENERS ************** //

	for (let j = 0; j < m17_triggers.length; j++) {
		m17_triggers[j].addEventListener("click", toggle_nav);
	}
})();
;/***************************************************
## ResponsiveTable
***************************************************/

const ResponsiveTable = (function() {
	const s = {
		tables: document.querySelectorAll(".responsive-table")
	};

	const getTableTitles = ths => {
		let thsText = [];
		doLoop(ths, function(index, th) {
			thsText.push(th.textContent);
		});
		return thsText;
	};

	const appendTitle = (rows, cell, title) => {
		doLoop(rows, function(index, row) {
			const tds = row.querySelectorAll(cell);
			doLoop(tds, function(i, td) {
				if (title[i] != null) {
					td.setAttribute("data-column", title[i]);
				}
			});
		});
	};

	const makeTableResponsive = () => {
		doLoop(s.tables, function(i, table) {
			const ths = table.querySelectorAll("th"),
				ths_values = getTableTitles(ths),
				trs = table.querySelectorAll("tr"),
				td = "td";
			appendTitle(trs, td, ths_values);
		});
	};

	const init = function() {
		makeTableResponsive();
	};

	return {
		init: init
	};
})();

{
	ResponsiveTable.init();
}
;(function() {
	"use strict";

	const searchClose = document.querySelector(".close-modal");
	const searchInput = document.querySelector("#search_modal_input");
	let pageBody = document.body;
	let pageHTML = document.getElementsByTagName("html")[0];
	let searchTriggers = document.querySelectorAll(".search_icon");
	let searchModal = document.querySelector(".search_modal");

	window.addEventListener(
		"load",
		function() {
			for (let i = 0; i < searchTriggers.length; i++) {
				searchTriggers[i].addEventListener(
					"click",
					function() {
						searchInput.focus();
						searchModal.classList.toggle("opened");
						pageHTML.classList.toggle("no-scroll");
						pageBody.classList.toggle("no-scroll");
					},
					false
				);
			}

			searchClose.addEventListener(
				"click",
				function() {
					searchModal.classList.toggle("opened");
					pageHTML.classList.toggle("no-scroll");
					pageBody.classList.toggle("no-scroll");
				},
				false
			);
		},
		false
	);
})();
;//JS tooltips

{
	var tooltips = document.getElementsByClassName("tooltip");
	var toolTipsArray = [];

	//add event listener to every tooltip
	if (tooltips.length >= 1) {
		for (var i = 0; i < tooltips.length; i++) {
			toolTipsArray.push(tooltips[i]);
			tooltips[i].addEventListener("click", showToolTip);
		}

		//close tooltip when anywhere on the window is clicked
		document.onclick = function(event) {
			if (event.target.className !== "tooltip" && event.target.className !== "tooltip-content") {
				for (var i = 0; i < toolTipsArray.length; i++) {
					toolTipsArray[i].querySelector(".tooltip-content").style.display = "none";
				}
			}
		};

		//close tooltip when anywhere on the window is tapped
		document.ontouchstart = function(event) {
			if (event.target.className !== "tooltip" && event.target.className !== "tooltip-content") {
				for (var i = 0; i < toolTipsArray.length; i++) {
					toolTipsArray[i].querySelector(".tooltip-content").style.display = "none";
				}
			}
		};
	}
}

//check the location of the tooltip on the screen and move it in if it's too close to the edge or if text is being cut off
function toolTipLocation(e, el) {
	var screenWidth = window.innerWidth,
		distanceFromL = el.offsetLeft,
		distanceFromR = screenWidth - distanceFromL,
		clickedTooltip = el.querySelector(".tooltip-content"),
		offset = 200;
	if (distanceFromR < 165) {
		clickedTooltip.style.left = "-" + offset + "px";
	}
}

//show/hide tooltip
function showToolTip(e) {
	toolTipLocation(e, this);
	var thisToolTipContent = this.querySelector(".tooltip-content");
	if (thisToolTipContent.style.display == "none") {
		thisToolTipContent.style.display = "block";
	}
	return this;
}
;/*********************
 ## ready
********************/

(function () {

})();

